<template>
    <div class="page-content">
        <!-- inner page banner -->
        <div class="dlab-bnr-inr overlay-black-dark banner-content bnr">
            <div class="container">
                <div class="dlab-bnr-inr-entry">
                    <h1 class="text-white">Academic</h1>
                    <!-- Breadcrumb row -->
                    <div class="breadcrumb-row">
                        <ul class="list-inline">
                            <li>
                                <router-link :to="{name: 'Home'}">Home</router-link>
                            </li>
                            <li>Academic</li>
                        </ul>
                    </div>
                    <!-- Breadcrumb row END -->
                </div>
            </div>
        </div>

        <!-- contact area -->
        <div class="content-block">
            <!-- Code Box -->
            <div class="section-full content-inner bg-white video-section">
                <div class="container">
                    <div class="row">

                        <div class="col-md-12">
                            <div class="dlab-accordion box-sort-in m-b30 green" id="accordion006">

                                <div class="panel">
                                    <div class="acod-head">
                                        <h4 class="acod-title"> <a href="javascript:void(0);" data-toggle="collapse"
                                                aria-expanded="false" data-target="#collapse1" class="collapsed">
                                                EYFS Stage ( FS1 - FS2 )</a> </h4>
                                    </div>
                                    <div id="collapse1" class="acod-body" data-parent="#accordion006">
                                        <div class="acod-content">
                                            <p style="margin-bottom:5px">
                                                The Early Years Foundation program is for children aged three and half
                                                to
                                                five
                                                years of
                                                age. We create an environment that promotes a happy and confident start
                                                to
                                                school life.
                                                Our Fs1 and Fs2 year groups each benefit from their own lively and
                                                well-resourced
                                                central areas, where they are encouraged to
                                                learn through play during their daily free-flow time. The children also
                                                have
                                                access to a
                                                large designed shaded playground.
                                            </p>
                                            <h6>The EYFS is based upon four principles:</h6>
                                            <ol class="list-num-count" style="margin-bottom: 15px">
                                                <li>A Unique Child</li>
                                                <li>Positive Relationships</li>
                                                <li>Enabling Environments</li>
                                                <li>Learning and Development</li>
                                            </ol>
                                            <h6>Children should mostly develop the 3 prime areas first. These are:</h6>
                                            <ol class="list-num-count" style="margin-bottom: 15px">
                                                <li>Communication and language</li>
                                                <li>Physical development</li>
                                                <li>Personal, social and emotional development</li>
                                            </ol>
                                            <h6>These prime areas are those most essential for your child`s healthy
                                                development
                                                and
                                                future learning. As children grow, the prime areas will help them to
                                                develop
                                                skills in 4
                                                specific areas. These are:</h6>
                                            <ol class="list-num-count" style="margin-bottom: 15px">
                                                <li>Literacy</li>
                                                <li>Mathematics</li>
                                                <li>Understanding the world</li>
                                                <li>Expressive arts and design</li>
                                            </ol>
                                            <p>Across those disciplines, our children are presented with a variety of
                                                problem-solving
                                                activities, designed to help them develop critical thinking skills and
                                                enhance
                                                their
                                                curiosity about the world around them which takes place both indoors and
                                                outside.</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="panel">
                                    <div class="acod-head">
                                        <h4 class="acod-title"> <a href="javascript:void(0);" data-toggle="collapse"
                                                aria-expanded="false" data-target="#collapse2" class="collapsed">
                                                Key Stage 1 (Year 1 and Year 2)</a> </h4>
                                    </div>
                                    <div id="collapse2" class="acod-body collapse" data-parent="#accordion006">
                                        <div class="acod-content">
                                            <p>
                                                We see the Lower Primary years (Key Stage One) as an important bridge
                                                between
                                                the
                                                fundamentals grasped in the Foundation Stage and the more rigorous
                                                concepts
                                                being
                                                grasped at Key Stage Two, and we work hard to make the transition a
                                                smooth
                                                one
                                                for our
                                                students. <br>
                                                Our curriculum comprises: English, Maths, Science, Arabic, Religion,
                                                German/
                                                French,
                                                Music, PE, Humanities (encompassing Geography and History), Art and
                                                Design.
                                                <br>
                                                Ongoing assessments and observations inform teachers of areas of focus
                                                for
                                                future
                                                planning and learning experiences whilst allowing teachers to
                                                successfully
                                                track
                                                and
                                                monitor student attainment and progress.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="panel">
                                    <div class="acod-head">
                                        <h4 class="acod-title"> <a href="javascript:void(0);" data-toggle="collapse"
                                                aria-expanded="false" data-target="#collapse3" class="collapsed">
                                                Key Stage 2 (Year 3 to Year 6)</a> </h4>
                                    </div>
                                    <div id="collapse3" class="acod-body collapse" data-parent="#accordion006">
                                        <div class="acod-content">
                                            <p>
                                                Our Key Stage 2 is an enjoyable time for students, as they begin to
                                                acquire
                                                more
                                                knowledge and skills such as independent learning, reflection and
                                                self-assessment. We
                                                encourage our students to be academically inquisitive and to continue to
                                                develop
                                                as
                                                independent learners, showing initiative and leadership skills. We
                                                create a
                                                learning
                                                environment in which students are pushed to extend and challenge their
                                                learning;
                                                to
                                                analyse and reflect; to take risks; and to develop intellectual
                                                confidence.
                                                <br>
                                                We follow Cambridge standards in English, Maths, Science and ICT along
                                                with
                                                the
                                                Foundation subjects of Art, Music, and Physical Education. In addition
                                                to
                                                this,
                                                Islamic
                                                studies/Christianity, Arabic and Egyptian Social Studies are an
                                                important
                                                part
                                                of the
                                                curriculum and given appropriate emphasis as required by the Egyptian
                                                Ministry
                                                of
                                                Education. <br>
                                                By the end of Year 6, we expect our students to have experienced a
                                                well-rounded
                                                education and have developed a strong character, articulate expression,
                                                confidence, and
                                                be well- prepared for their secondary education. The students write
                                                Primary
                                                checkpoint
                                                examination conducted by Cambridge International Examinations at the end
                                                of
                                                year
                                                6. <br>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="panel">
                                    <div class="acod-head">
                                        <h4 class="acod-title"> <a href="javascript:void(0);" data-toggle="collapse"
                                                aria-expanded="false" data-target="#collapse4" class="collapsed">
                                                Key Stage 3 (Year 7 to Year 9)</a> </h4>
                                    </div>
                                    <div id="collapse4" class="acod-body collapse" data-parent="#accordion006">
                                        <div class="acod-content">
                                            <p>
                                                The start of Key Stage 3 is a transition year when our students are
                                                moving
                                                from
                                                the
                                                Primary school. The transition to Secondary School can often be a big
                                                step
                                                and
                                                we ensure
                                                that each student is given the appropriate amount of support to adapt to
                                                a
                                                more
                                                intellectually challenging curriculum. <br>During Key Stage 3 we
                                                encourage
                                                active
                                                learning, critical thinking and excellent communication skills including
                                                taking
                                                part in
                                                debates and giving presentations. Our students represent the school at
                                                various
                                                functions
                                                and participate in the school council and other meetings. Specialist
                                                subjects
                                                begin to
                                                embed subject knowledge in preparation for the IGCSEs. <br> Learning is
                                                frequently
                                                enhanced by various activities, experiments and trips in order to allow
                                                our
                                                students to
                                                see the learned topics embedded in practice. <br> By the end of Year 9,
                                                students
                                                sit for
                                                Cambridge Core Exams in English as a second language, Mathematics,
                                                Biology,
                                                Chemistry
                                                and Physics. Students are also required to study the Ministry of
                                                Education
                                                curriculum
                                                for Arabic Language, Religion & Social Studies & pass the Ministry
                                                exams.
                                                <br>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="panel">
                                    <div class="acod-head">
                                        <h4 class="acod-title"> <a href="javascript:void(0);" data-toggle="collapse"
                                                aria-expanded="false" data-target="#collapse5" class="collapsed">
                                                Key Stage 4 (Year 10 And 11) And IGCSE Program</a> </h4>
                                    </div>
                                    <div id="collapse5" class="acod-body collapse" data-parent="#accordion006">
                                        <div class="acod-content">
                                            <table class="table table-striped" style="text-align:center">
                                                <thead>
                                                    <tr>
                                                        <th colspan="2" scope="col">IGCSE Teachers 2022 - 2023</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style="width:50%">&nbsp;</td>
                                                        <td style="border-left: 1px solid gray;">ESL</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width:50%">&nbsp;</td>
                                                        <td style="border-left: 1px solid gray;">Maths</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width:50%">&nbsp;</td>
                                                        <td style="border-left: 1px solid gray;">Chemistry</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width:50%">&nbsp;</td>
                                                        <td style="border-left: 1px solid gray;">Physics</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width:50%">&nbsp;</td>
                                                        <td style="border-left: 1px solid gray;">Biology</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width:50%">&nbsp;</td>
                                                        <td style="border-left: 1px solid gray;">Business</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width:50%">&nbsp;</td>
                                                        <td style="border-left: 1px solid gray;">French</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width:50%">&nbsp;</td>
                                                        <td style="border-left: 1px solid gray;">German</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <p style="margin-bottom:5px">
                                                Our students are taught by specialist teachers who are real enthusiasts
                                                for
                                                their own
                                                subjects, and who guide them to opt for a range of specialist subjects
                                                to
                                                tailor
                                                their
                                                learning to their interests and to study in more depth during this time.
                                            </p>
                                            <p style="margin-bottom:5px">Key stage 4 students study the compulsory
                                                subjects
                                                English as
                                                2nd.language, Maths, Chemistry, MOE subjects and choose from the options
                                                below:
                                            </p>
                                            <ol class="list-num-count" style="margin-bottom: 15px">
                                                <li>Physics/ Biology</li>
                                                <li>French/ German</li>
                                            </ol>
                                            <p>Depending upon students` chosen university requirements, they will be
                                                able to
                                                take
                                                qualifications from either Cambridge Assessment International Education,
                                                Oxford
                                                or
                                                Pearson Edexcel International.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .bnr {
        background-image: url("~@/assets/imgs/bnr1.jpg") !important;
    }
</style>